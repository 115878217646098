import { HttpClient } from '@angular/common/http';
import { Injectable, InjectionToken } from '@angular/core';

import { FilePlugin } from '../native-plugins/file.plugin';

import { catchError, from, map, Observable, timeout } from 'rxjs';

import { InitialSettings } from '../../models/initial-settings.model';

import { AppService } from '../app.service';
import { EnvironmentService } from '../environment.service';
import { LoggerService } from '../logger.service';
import { PlatformService } from './platform.service';

import { SETTINGS_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS } from '../../config/app.config';

export const SETTINGS_LOADER = new InjectionToken<string>('SETTINGS_LOADER');

export declare abstract class SettingsLoader {
  public abstract getSettings(): Observable<InitialSettings>;
}

@Injectable({ providedIn: 'root' })
export class SettingsBrowserLoader implements SettingsLoader {
  constructor(
    private http: HttpClient,
    private filePlugin: FilePlugin,
    private appService: AppService,
    private loggerService: LoggerService,
    private platformService: PlatformService,
    private environmentService: EnvironmentService,
  ) {}

  public getSettings(): Observable<InitialSettings> {
    const appVersion = this.appService.getAppVersion();
    const initialSettingsUrl = this.environmentService.initialSettingsUrl;

    return this.http.get<InitialSettings>(`${initialSettingsUrl}/${appVersion}.json`).pipe(
      timeout(SETTINGS_CDN_REQUEST_TIMEOUT_IN_MILLISECONDS),
      catchError((error: Error) => {
        this.loggerService.info({ component: 'SettingsService', message: "Couldn't load initial settings from CDN", details: { error } });
        return this.platformService.areCordovaPluginsAvailable && this.platformService.isIos
          ? this.loadInitialSettingsFileForIos()
          : this.loadInitialSettingsFileForAndroidAndWeb();
      }),
    );
  }

  private loadInitialSettingsFileForIos(): Observable<InitialSettings> {
    return from(this.filePlugin.readAsText(this.filePlugin.applicationDirectory, 'www/assets/config/settings.json')).pipe(
      map((fileContent) => JSON.parse(fileContent) as InitialSettings),
    );
  }

  private loadInitialSettingsFileForAndroidAndWeb(): Observable<InitialSettings> {
    return this.http.get<InitialSettings>('/assets/config/settings.json');
  }
}
